/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, FC } from 'react';

import { LoadingOverlay, Table } from 'xpand-ui/core';
import { parseDateToShow } from 'xpand-ui/utils/dates';

import { ITableColumn } from 'typings/store/ComponentLib';

import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';

import { MyTrainingProps } from '.';

const MyTraining: FC<MyTrainingProps> = ({
	personalInfo,
	filters,
	getUserTrainings,
	clearPersonalInfoError,
	setMyTrainingsPageFilter
}) => {
	const { error, userTrainings } = personalInfo;
	const { myTrainingsFilter } = filters;

	// TABLE COMPONENT - search state field
	const [searchValue, setSearchValue] = useState(myTrainingsFilter);

	// Update filters whenever necessary
	useEffect(() => {
		setMyTrainingsPageFilter(searchValue);
	}, [searchValue]);

	/* Checking if userTrainings is null and if it is, it will call getUserTrainings() */
	useEffect(() => {
		if (!userTrainings && !error) getUserTrainings();
	}, [userTrainings]);

	// TABLE COMPONENT - columns
	const tableColumns: ITableColumn<any>[] = [
		{
			label: 'Description',
			id: 'description',
			accentColumn: true,
			align: 'left',
			width: '30%',
			maxWidth: '50%'
		},
		{ label: 'Place', id: 'place' },
		{ label: 'Duration', id: 'hoursnumberParsed' },
		{
			label: 'Starting Date',
			id: 'startingDate',
			type: 'date',
			format: (row, text: string) => (text ? parseDateToShow(text) : '')
		},
		{
			label: 'Certification Expiration Date',
			id: 'certificationExpiryDate',
			type: 'date',
			format: (row, text: string) => (text ? parseDateToShow(text) : '')
		}
	];
	function doubleToHoursMinutes(decimalHours: any) {
		const hours = Math.floor(decimalHours);
		const minutes = Math.round((decimalHours - hours) * 60);
		return minutes == 0 ? `${hours}H` : `${hours}.${minutes}H`;
	}

	const tableData = useMemo(
		() =>
			userTrainings?.trainings?.map(e => ({
				...e,
				startingDateParsed: e.startingDate ? parseDateToShow(e.startingDate) : '',
				endingDateParsed: e.endingDate ? parseDateToShow(e.endingDate) : '',
				certificationDateParsed: e.certificationDate ? parseDateToShow(e.certificationDate) : '',
				certificationExpiryDateParsed: e.certificationExpiryDate
					? parseDateToShow(e.certificationExpiryDate)
					: '',
				hoursnumberParsed: e.hoursnumber ? doubleToHoursMinutes(e.hoursnumber) : ''
			})),
		[userTrainings]
	);

	/* Checking if there is an error and if there is, it will return the handleErrorPage function. */
	if (error) return handleErrorPage(error, clearPersonalInfoError);

	/* Checking if the userTrainings is null and if it is, it will return the LoadingOverlay component. */
	const isLoading = userTrainings === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<Table
			tableData={tableData}
			columns={tableColumns}
			defaultSortColumn="startingDate"
			defaultOrder="desc"
			handleSearch={{ searchValue, setSearchValue }}
		/>
	);
};

export default withLayout(MyTraining);
