import React, { useEffect, useState, useMemo, FC, useCallback, useRef } from 'react';
import { LoadingOverlay, Dialog } from 'xpand-ui/core';
import { Select, InputLabel, DatePicker, Input } from 'xpand-ui/forms';
import { Eventcalendar, setOptions, localePtPT, Popup } from '@mobiscroll/react';
import withLayout, { handleErrorPage } from 'lib/hocs/withLayout';
import { useStyles } from './styles';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IChoosableBaseInfo, ISelectedOption } from 'typings/store/generalTypes';
import { schema, defaultValues } from './yupSchema'; // Virtualized list
import { START_API_PATH } from 'lib/utils/constants';
import { IEvent, IFilters, IPlannerData, IProject, ITimeOff } from 'typings/store/admin/planner';
import { Grid, Button, Switch } from '@mui/material';
import { Fullscreen } from '@mui/icons-material';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { parseDateToManage, parseDateToManageSaf } from 'xpand-ui/utils/dates';
import {
	getFilterOfficesDropdownData,
	getFilterProjectDropdownData,
	getFilterSkillsDropdownData,
	getFilterUsersDropdownData
} from './utils/helper';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './PlannerMobiscroll.css';
import { enGB } from 'date-fns/locale';

// Mobiscroll Configuration
setOptions({
	locale: enGB,
	theme: 'material',
	themeVariant: 'light'
});

const COLORS = [
	{ id: 'red', label: 'Red' },
	{ id: 'pink', label: 'Pink' },
	{ id: 'purple', label: 'Purple' },
	{ id: 'violet', label: 'Violet' },
	{ id: 'indigo', label: 'Indigo' },
	{ id: 'blue', label: 'Blue' },
	{ id: 'cyan', label: 'Cyan' },
	{ id: 'teal', label: 'Teal' },
	{ id: 'green', label: 'Green' },
	{ id: 'gantt-green', label: 'Gantt-green' },
	{ id: 'lime', label: 'Lime' },
	{ id: 'yellow', label: 'Yellow' },
	{ id: 'orange', label: 'Orange' },
	{ id: 'deep-orange', label: 'Deep-orange' },
	{ id: 'gray', label: 'Gray' }
];

const PlannerMobiscroll: FC<PlannerProps> = ({
	planner,
	filters,
	getPlannerData,
	getFiltersData,
	getFilteredPlannerData,
	setPlannerFilters,
	savePlannerNewData,
	deletePlannerEvent,
	clearPlannerError
}) => {
	// General data
	const classes = useStyles();
	const { plannerData, plannerFilteredData, filtersData, loading, error } = planner;
	const { plannerFilters } = filters;

	// Events Related
	const [events, setEvents] = useState<IEvent[]>([]);
	const [timesOff, settimesOff] = useState<ITimeOff[]>([]);

	// Filters Related
	const [filtersProjects, setFiltersProjects] = useState<IChoosableBaseInfo[]>([]);
	const [filtersOffices, setFiltersOffices] = useState<IChoosableBaseInfo[]>([]);
	const [filtersSkills, setFiltersSkills] = useState<IChoosableBaseInfo[]>([]);
	const [filtersUsers, setFiltersUsers] = useState<IChoosableBaseInfo[]>([]);
	const [filtersHolder, setFiltersHolder] = useState<IFilters>(plannerFilters);
	const [lastfiltersAppliedHolder, setLastfiltersAppliedHolder] = useState<IFilters | null>(plannerFilters);
	const [editFilters, setEditFilters] = useState(false);
	const [filterCount, setFilterCount] = useState(0);

	// New Entry Related
	const [newEntryModal, setNewEntryModal] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [tempEventData, setTempEventData] = useState();
	useEffect(() => {
		setPlannerFilters(lastfiltersAppliedHolder);
	}, [lastfiltersAppliedHolder]);

	const {
		handleSubmit,
		control,
		reset,
		setValue,
		watch,
		formState: { errors }
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
		defaultValues,
		shouldUnregister: false
	});

	useEffect(() => {
		if (!plannerData && !error) {
			getPlannerData();
		}
	}, [plannerData]);

	useEffect(() => {
		if (!filtersData && !error) {
			getFiltersData();
		} else {
			setFiltersProjects(getFilterProjectDropdownData(filtersData.projects));
			setFiltersOffices(getFilterOfficesDropdownData(filtersData.offices));
			setFiltersSkills(getFilterSkillsDropdownData(filtersData.skills));
			setFiltersUsers(getFilterUsersDropdownData(filtersData.users));
		}
	}, [filtersData]);

	//Fullscreen related
	const rootElement = useRef<HTMLDivElement>(null);
	const setFullscreen = () => {
		if (!document.fullscreenElement && rootElement.current != null) {
			document.documentElement.requestFullscreen();
		} else if (document.exitFullscreen) {
			document.exitFullscreen();
		}
	};

	const getProjectIdLabel = (projectID: string): string => {
		const projectC = filtersData?.projects?.find((project: IProject) => project.id === projectID);
		return (projectC && projectC.name) || '';
	};

	const resetFiltersValues = () => {
		reset({
			...defaultValues,
			startDate: lastfiltersAppliedHolder?.startDate || '',
			endDate: lastfiltersAppliedHolder?.endDate || '',
			offices: lastfiltersAppliedHolder?.offices || [],
			projects: lastfiltersAppliedHolder?.projects || [],
			users: lastfiltersAppliedHolder?.users || [],
			skills: lastfiltersAppliedHolder?.skills || [],
			showAvailableOnly: lastfiltersAppliedHolder?.showAvailableOnly || false,
			showTimeOff: lastfiltersAppliedHolder?.showTimeOff || true
		});
	};

	// Create filter Data payload and request
	const makeFilterPlannerDataRequest = (values: IFilters) => {
		const { startDate, endDate, ...otherFilters } = values;

		const startDateFinal = new Date(parseDateToManageSaf(startDate));

		const endDateAux = new Date(parseDateToManageSaf(endDate));
		const endDateFinal = new Date(endDateAux.getFullYear(), endDateAux.getMonth() + 1, 0);

		getFilteredPlannerData({
			...otherFilters,
			startDate: startDate && parseDateToManage(startDateFinal, true),
			endDate: endDate && parseDateToManage(endDateFinal, true)
		});
		setEditFilters(false);
	};

	// Update planner filters based on user saved filters
	useEffect(() => {
		makeFilterPlannerDataRequest(lastfiltersAppliedHolder as IFilters);
		setLastfiltersAppliedHolder(lastfiltersAppliedHolder);
		setFiltersHolder(lastfiltersAppliedHolder ? (lastfiltersAppliedHolder as IFilters) : defaultValues);
		resetFiltersValues();
	}, [lastfiltersAppliedHolder]);

	const myView = {
		timeline: {
			type: 'month', // Options: 'day', 'week', 'month', etc.
			size: 24, // Number of days visible in the timeline
			scrollable: true, // Ensures that the timeline is scrollable
			// You can also set "maxHeight" to make it vertically scrollable
			eventList: true // Allows event listing
		}
	};

	const myInvalid = [
		{
			start: '00:00',
			end: '24:00',
			title: '',
			recurring: {
				repeat: 'weekly',
				weekDays: 'SA,SU'
			},
			cssClass: 'md-lunch-break-class mbsc-flex'
		}
	];

	const myCustomEvent = useCallback(args => {
		if (args.title === 'Holiday' || args.title === 'Vacation') return;

		if (args.title !== 'Holiday' || args.title !== 'Vacation')
			return (
				<div>
					<div
						style={{
							display: 'flow',
							color: 'white'
						}}>
						{args.title} - ( {args.original.allocationPercentage} % )
					</div>
				</div>
			);
	}, []);

	// Transform the planner resources into the required format
	const transformResources = resources => {
		const groupedResources = {};
		resources?.resources.forEach(resource => {
			const groupKey = resource.group.toLowerCase().replace(/\s+/g, '');

			if (!groupedResources[groupKey]) {
				groupedResources[groupKey] = {
					id: groupKey,
					name: resource.group,
					eventCreation: false,
					children: []
				};
			}
			groupedResources[groupKey].children.push({
				id: resource.id,
				name: resource.name,
				title: resource.id,
				img: 'https://img.mobiscroll.com/demos/m1.png',
				color: '#1dab2f' // Default color; adjust as necessary
			});
		});
		return Object.values(groupedResources);
	};

	const renderMyResource = useCallback(resource => {
		return (
			<div className={classes.recordItem}>
				{resource.isParent ? (
					<div
						style={{
							marginTop: '-5px', // Moves it slightly up
							marginLeft: '-18px' // Moves it slightly to the left
						}}>
						<span className={classes.recordItemName}>{resource.name}</span>
					</div>
				) : (
					<>
						<div className={classes.avatarContainer}>
							<img
								src={`${START_API_PATH}/admin/users/${resource.id}/photo?small=true`}
								alt=""
								className={classes.avatarImage}
							/>
						</div>
						<div>
							<span className={classes.recordItemName}>{resource.name}</span>
							<span className={classes.recordItemUsername}>{resource.id}</span>
						</div>
					</>
				)}
			</div>
		);
	}, []);

	useEffect(
		() => () => {
			// check if in fullscreen mode
			if (document && !!document.fullscreenElement) {
				document.exitFullscreen();
			}
			resetStateFiltersValues(false);
		},
		[]
	);

	const resetStateFiltersValues = (inPageReset = false) => {
		reset(defaultValues);
		setFiltersHolder(defaultValues);
		setLastfiltersAppliedHolder(defaultValues);
		if (inPageReset) {
			makeFilterPlannerDataRequest(defaultValues);
		}
	};

	/**  updates:
	 * @field Scheduler Dates
	 * @field Projects
	 * @field Events
	 * @field TimeOffs
	 */
	const updatePlannerData = (payload: IPlannerData, first = false) => {
		setEvents(
			payload.events.map((e: IEvent) => ({
				...e,
				locked: true,
				alternativeProjLabel: getProjectIdLabel(e.projectID),
				name: e.projectLabel ? e.projectLabel : getProjectIdLabel(e.projectID)
			}))
		);
		settimesOff(payload.timeOffs);
	};

	useEffect(() => {
		if (!plannerData && !error) {
			getPlannerData();
		} else {
			if (!plannerData) return;
			updatePlannerData(plannerData, true);
		}
	}, [plannerData]);

	const myResources2 = useMemo(() => transformResources(plannerFilteredData), [plannerFilteredData]);

	const mergedEvents = useMemo(() => {
		const normalEvents = events.map(event => ({
			id: event.eventId,
			start: event.startDate,
			end: event.endDate,
			title: event.name,
			resource: event.resourceId,
			projectId: event.projectID,
			color: event.eventColor,
			allocationPercentage: event.allocationPercentage
		}));

		const timeOffEvents = timesOff.map(timeOff => {
			const startDate = new Date(timeOff.startDate); // Parse startDate as a Date object
			const endDate = new Date(startDate); // Create a copy for manipulation

			endDate.setDate(endDate.getDate() + timeOff.duration - 1); // Add duration days, accounting for single-day holidays

			return {
				id: timeOff.id,
				start: timeOff.startDate, // Keep original startDate as is
				end: endDate.toISOString().split('T')[0], // Convert to ISO string and ensure format
				title: timeOff.name, // Label for the time-off
				resource: timeOff.resourceId, // Resource ID
				color: '#FCDDDB', // Optional: color for distinguishing time-offs
				editable: false
			};
		});

		return [...normalEvents, ...timeOffEvents]; // merge events and time-offs
	}, [events, timesOff]);

	// Handle for Show time off option in filters
	const handleShowTimeOffSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFiltersHolder(prev => ({
			...prev,
			showTimeOff: event.target.checked
		}));
	};

	// Handle for Show time off option in filters
	const handleShowAvailableOnlySwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
		// Show Time off must be true if show available is true
		setFiltersHolder(prev => ({
			...prev,
			showAvailableOnly: event.target.checked,
			showTimeOff: event.target.checked ? true : prev.showTimeOff
		}));
	};

	const filtersModalActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				setEditFilters(false);
				setFiltersHolder(lastfiltersAppliedHolder ? (lastfiltersAppliedHolder as IFilters) : defaultValues);
				resetFiltersValues();
			}
		},
		{
			id: 'reset',
			label: 'Reset',
			color: 'secondary',
			variant: 'outlined',
			onClick: () => {
				resetStateFiltersValues(true);
			}
		},
		{
			id: 'submit',
			label: 'Apply',
			color: 'primary',
			type: 'submit',
			form: 'form-filters-planner',
			variant: 'contained',
			onClick: () => null
		}
	];

	const saveEventChanges = (watchData, tempData, action, filtersHolder) => {
		const result = createPostEventsPayload(watchData, tempData, action);
		savePlannerNewData(result);
		getPlannerData();
	};

	const onSubmit = () => {
		// save last filters applied
		setLastfiltersAppliedHolder(filtersHolder);
		// request
		makeFilterPlannerDataRequest(filtersHolder);
	};

	const submitActions = [
		{
			id: 'cancel',
			label: 'Cancel',
			color: 'secondary',
			variant: 'text',
			onClick: () => {
				// setTempEventData({});
				setNewEntryModal(false);
				setIsEditMode(false);
				reset(defaultValues);
			}
		},
		{
			id: 'confirm',
			label: 'Confirm',
			color: 'primary',
			variant: 'contained',
			onClick: () => {
				saveEventChanges(watch(), tempEventData, isEditMode ? 'Update' : 'Create', filtersHolder);
				// setTempEventData({});
				setNewEntryModal(false);
				setIsEditMode(false);
				reset(defaultValues);
			}
		},
		// Conditionally add the delete action if isEditMode is true
		...(isEditMode
			? [
					{
						id: 'delete',
						label: 'Delete',
						color: 'primary',
						variant: 'contained',
						onClick: () => {
							deletePlannerEvent({
								id: tempEventData?.event?.id
							});
							// setTempEventData({});
							setNewEntryModal(false);
							setIsEditMode(false);
							getPlannerData();
						}
					}
			  ]
			: [])
	];

	const onSubmitNewEvent = () => {};

	const transformDate = (date: string | Date): string => moment(date).format('YYYY-MM-DD HH:mm:ss');

	const createPostEventsPayload = (watchData, tempData, action: string): IEvent[] => {
		const parsedEvent: IEvent = {
			eventId: tempData.event.id ? tempData.event.id : uuidv4(),
			resourceId: watchData.resource,
			startDate: transformDate(watchData.startDate.toString()),
			projectID: watchData.projects,
			endDate: transformDate(watchData.endDate.toString()),
			eventColor: watchData.color,
			projectLabel: watchData.projectLabel,
			allocationPercentage: watchData.allocation,
			operation: action
		};

		return parsedEvent;
	};

	//Tooltip related
	const [appointmentTitle, setAppointmentTitle] = useState('');
	const [appointmentStartDate, setAppointmentStartDate] = useState('');
	const [appointmentEndDate, setAppointmentEndDate] = useState('');
	const [isTooltipOpen, setTooltipOpen] = useState(false);
	const [tooltipAnchor, setTooltipAnchor] = useState(null);

	const openTooltip = useCallback(args => {
		const event = args.event;
		setAppointmentTitle(event.title);
		let startTime = moment(event.start);
		setAppointmentStartDate(startTime.toString().padStart(2, '0'));
		let endTime = moment(event.end);
		setAppointmentEndDate(endTime.toString().padStart(2, '0'));
		setTooltipAnchor(args.domEvent.target.closest('.mbsc-timeline-event'));
		setTooltipOpen(true);
	}, []);

	const onEventCreated = React.useCallback(args => {
		// Check if the event crosses dates and adjust if necessary
		setValue('resource', args.event.resource);
		setValue('startDate', moment(args.event.start).utcOffset(0, true).format());
		setValue('endDate', moment(args.event.end).utcOffset(0, true).format());
		setValue('projectLabel', args.event.title);
		setValue('color', args.event.color);
		setValue('allocation', args.event.allocationPercentage);
		setValue('projects', args.event.projectId);
	}, []);

	const onEventDoubleClick = React.useCallback(args => {
		// Check if the event crosses dates and adjust if necessary
		setValue('resource', args.event.resource);
		setValue('startDate', moment(args.event.start).utcOffset(0, true).format());
		setValue('endDate', moment(args.event.end).utcOffset(0, true).format());
		setValue('projectLabel', args.event.title);
		setValue('color', args.event.color);
		setValue('allocation', args.event.allocationPercentage);
		setValue('projects', args.event.projectId);
		setValue('eventId', args.event.eventId);
	}, []);

	const handleEventHoverIn = useCallback(args => {
		openTooltip(args);
	}, []);

	const handleEventHoverOut = useCallback(() => {
		setTooltipOpen(false);
	}, []);

	const handleTooltipClose = useCallback(() => {
		setTooltipOpen(false);
	}, []);

	if (error) return handleErrorPage(error, clearPlannerError);

	const isLoading = plannerData === null || plannerFilteredData === null;
	if (isLoading) return <LoadingOverlay />;

	return (
		<div className={classes.root} ref={rootElement}>
			<div className={classes.actionsHeader}>
				<div className={classes.leftActions}>
					<Button
						className={classes.fullscreenButton}
						color="secondary"
						onClick={setFullscreen}
						endIcon={<Fullscreen />}>
						Fullscreen
					</Button>
					<Button
						className={filterCount ? classes.filterButtonSelected : classes.filterButton}
						variant="outlined"
						color="primary"
						style={{
							padding: filterCount > 0 ? '7px 30px 7px 30px' : '7px 35px 7px 35px'
						}}
						onClick={() => setEditFilters(true)}>
						filters {filterCount > 0 ? `+${filterCount}` : ''}
					</Button>
					{/* {(!!filtersHolder?.startDate?.length || !!filtersHolder?.endDate?.length) &&
						showDateFilterIfActive()} */}
				</div>
				<div></div>
			</div>
			{loading && <LoadingOverlay />}
			<div className={classes.actionsHeader}>
				<Eventcalendar
					clickToCreate={true}
					onCellClick={e => {
						setTempEventData(e);
						setNewEntryModal(true);
					}}
					onEventDragEnd={e => {
						setTempEventData(e);
						setNewEntryModal(true);
					}}
					onEventDoubleClick={e => {
						onEventDoubleClick(e);
						setTempEventData(e);
						setNewEntryModal(true);
						setIsEditMode(true);
					}}
					onEventCreated={onEventCreated}
					dragToCreate={true}
					dragToMove={false}
					renderScheduleEventContent={myCustomEvent}
					showEventTooltip={false}
					dragToResize={false}
					eventDelete={true}
					onEventHoverIn={handleEventHoverIn}
					onEventHoverOut={handleEventHoverOut}
					view={myView}
					data={tempEventData ? mergedEvents.filter(event => event.id !== tempEventData.id) : mergedEvents}
					// data={mergedEvents}
					invalid={myInvalid}
					renderResource={renderMyResource}
					resources={myResources2}
					height={600} // Set fixed height for scrollable calendar
				/>
			</div>
			<Dialog
				title={isEditMode ? 'Edit Entry' : 'New Entry'}
				actions={submitActions}
				scroll="body"
				modal={{
					// Sending Edit or Add mode
					open: Boolean(newEntryModal),
					handleClose: () => setNewEntryModal(false),
					content: (
						<form id="form-new-entry-planner" onSubmit={handleSubmit(onSubmitNewEvent)}>
							<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									<Select
										name="resource"
										label="Resource"
										options={filtersUsers}
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item sm={6}>
									<DatePicker name="startDate" label="Start Date" control={control} errors={errors} />
								</Grid>
								<Grid item sm={6}>
									<DatePicker name="endDate" label="End Date" control={control} errors={errors} />
								</Grid>
								<Grid item xs={12}>
									<Input
										name="allocation"
										label="Allocation"
										default="100"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="projects"
										label="Projects"
										options={filtersProjects}
										additionalOnChange={(item: ISelectedOption) => {
											setFiltersHolder(prev => ({
												...prev,
												projects: item.value
											}));
										}}
										helperText="Select one or more projects"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<Input
										name="projectLabel"
										label="Project Label"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="color"
										label="Color"
										options={COLORS}
										control={control}
										errors={errors}
									/>
								</Grid>
							</Grid>
						</form>
					)
				}}
			/>
			<Dialog
				title="Select Filters"
				actions={filtersModalActions}
				scroll="body"
				modal={{
					// Sending Edit or Add mode
					open: Boolean(editFilters),
					handleClose: () => setEditFilters(false),
					content: (
						<form id="form-filters-planner" onSubmit={handleSubmit(onSubmit)}>
							<Grid container spacing={2} style={{ padding: '10px 5px 40px 5px' }}>
								<Grid item xs={12}>
									<Select
										name="offices"
										label="Offices"
										multiple
										options={filtersOffices}
										additionalOnChange={(item: ISelectedOption) => {
											setFiltersHolder(prev => ({
												...prev,
												offices: item.value
											}));
										}}
										helperText="Select one or more offices"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item sm={6}>
									<DatePicker
										label="Start Date"
										name="startDate"
										placeholder="MM-YYYY"
										inputFormat="MM-YYYY"
										openTo="month"
										views={['year', 'month']}
										additionalOnChange={date => {
											setFiltersHolder(prev => ({
												...prev,
												startDate: date || undefined
											}));
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item sm={6}>
									<DatePicker
										label="End Date"
										name="endDate"
										placeholder="MM-YYYY"
										inputFormat="MM-YYYY"
										openTo="month"
										views={['year', 'month']}
										additionalOnChange={date => {
											setFiltersHolder(prev => ({
												...prev,
												endDate: date || undefined
											}));
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
								<div className={classes.switchContainer}>
									<InputLabel className={classes.switchLabel}>SHOW ONLY AVAILABLE</InputLabel>{' '}
									<Switch
										color="primary"
										checked={filtersHolder.showAvailableOnly}
										onChange={handleShowAvailableOnlySwitch}
									/>
								</div>
								<Grid item xs={12}>
									<Select
										name="projects"
										label="Projects"
										multiple
										options={filtersProjects}
										additionalOnChange={(item: ISelectedOption) => {
											setFiltersHolder(prev => ({
												...prev,
												projects: item.value
											}));
										}}
										helperText="Select one or more projects"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="users"
										label="Users"
										multiple
										options={filtersUsers}
										additionalOnChange={(item: ISelectedOption) => {
											setFiltersHolder(prev => ({
												...prev,
												users: item.value
											}));
										}}
										helperText="Select one or more users"
										control={control}
										errors={errors}
									/>
								</Grid>
								<Grid item xs={12}>
									<Select
										name="skills"
										label="Skills"
										multiple
										options={filtersSkills}
										additionalOnChange={(item: { name: string; value: number[] }) => {
											setFiltersHolder(prev => ({
												...prev,
												skills: item.value
											}));
										}}
										helperText="Select one or more skills"
										control={control}
										errors={errors}
									/>
								</Grid>
								<div className={classes.switchContainer}>
									<InputLabel className={classes.switchLabel}>SHOW VACATIONS</InputLabel>{' '}
									<Switch
										color="primary"
										disabled={filtersHolder.showAvailableOnly}
										checked={filtersHolder.showAvailableOnly || filtersHolder.showTimeOff}
										onChange={handleShowTimeOffSwitch}
									/>
								</div>
							</Grid>
						</form>
					)
				}}
			/>
			<Popup
				anchor={tooltipAnchor}
				contentPadding={false}
				display="anchored"
				isOpen={isTooltipOpen}
				scrollLock={false}
				showOverlay={false}
				touchUi={false}
				width={350}
				onClose={handleTooltipClose}>
				<div className={classes.mdsTooltip}>
					<div className={classes.mdsTooltipHeader}>
						<span>{appointmentTitle}</span>
					</div>
					<div className={classes.mdsTooltipContent}>
						<span className={classes.mdsTooltipContentItem}>
							<i className={classes.mdsTooltipIcon}>🕒</i> {appointmentStartDate}
						</span>
						<span className={classes.mdsTooltipContentItem}>
							<i className={classes.mdsTooltipIcon}>🕒</i> {appointmentEndDate}
						</span>
					</div>
				</div>
			</Popup>
		</div>
	);
};

export default withLayout(PlannerMobiscroll);
