/* eslint-disable @typescript-eslint/no-explicit-any */
//* EXTERNAL LIBS
import React, { FC, useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';

//* EXTERNAL LIBS --> MUI
import { Grid, Divider, Button, Typography, Popover, IconButton } from '@mui/material';
import { UnfoldMore, Edit } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';

//* EXTERNAL LIBS --> XPAND-UI
import { Input, Numeric, Select, Checkbox, InfoField, DatePicker, SearchableSelect } from 'xpand-ui/forms';
import { sizes } from 'xpand-ui/utils/handlers';

//* TYPINGS
//* PROJECT IMPORTS [LIB / PAGES ]
import withLayout from 'lib/hocs/withLayout';

//* LOCAL COMPONENT IMPORTS
import GroupCollapse from '../components/GroupCollapse';
import { CSC, COLLABTYPE, EMPLOYEE, PROPOSAL } from '../constants';
import { INSTANCE } from 'lib/utils/constants';
import PrizeGoals from './PrizeGoals';
import { useStyles } from './styles';
import { ITableAction } from 'typings/store/ComponentLib';
import { addNotification } from 'lib/utils/notifications';
import { CircularProgress } from '@mui/material';
import { setProposalSummary } from 'store/administration/proposals/actions';
import { useDispatch } from 'react-redux';
import { formatCurrency } from '../utils';
import { getValue } from '@mui/system';

//* COMPONENT INTERFACES
//* COMPONENT
const CareerFinancial: FC<any> = ({
	type,
	getAnnualGrossValue,
	updateIRSTax,
	control,
	errors,
	watch,
	reset,
	clearErrors,
	getValues,
	setValue,
	selectOptions,
	handleSelectHelper,
	initFinancialValues,
	updateFoodAllowanceValue,
	newProposal,
	newEmployee,
	newEmployeeSalary,
	isContractor,
	initialFormData,
	isEdit,
	employee,
	formFields,
	tableFilterSearch,
	filters,
	filtersRows,
	getProposalSummary,
	proposals
}) => {
	const classes = useStyles();
	const [editMode, setEditMode] = useState(type == CSC ? false : true);
	const [viewExpanded, setViewExpanded] = useState(true);
	const [isManuallyEdited, setIsManuallyEdited] = useState(false);
	const [snapshot, setSnapshot] = useState(null);
	const [isPTCompany, setPTCompany] = useState(false);
	const [addExpensesWhenNursery0, setAddExpensesWhenNursery0] = useState(getValues('additionalExpenses'));
	const [isColType, setIsColType] = useState(type == COLLABTYPE ? true : false);
	const [holderStatusOptions, setHolderStatusOptions] = useState([
		{ id: '1', label: 'Single Holder' },
		{ id: '2', label: 'Two Holders' }
	]);
	const [budgetValue, setBudgetValue] = useState(0);
	const [surtaxZoneList, setSurtaxZoneList] = useState();
	const [currentJobRoleName, setCurrentJobRoleName] = useState('');
	const [currentJobRoleHasPlace, setCurrentJobRoleHasPlace] = useState(false);
	const [filtersValue, setFiltersValue] = useState('');
	const [tableData, setTableData] = useState([]);
	//Monthly Net Amount and Annual Cost - Simulation
	const [simulatedRow, setSimulatedRow] = useState(null);
	const [simulatedRows, setSimulatedRows] = useState([]);
	const dispatch = useDispatch();
	const { proposalSummary, isProposalSummaryloading } = proposals;
	const [anchorNetAmountEl, setAnchorNetAmountEl] = useState<any>(null);
	const [anchorSimNetAmountEl, setAnchorSimNetAmountEl] = useState<any>(null);

	const handleClickNetAmount = (event: any) => setAnchorNetAmountEl(event.currentTarget);
	const handleClickSimNetAmount = (event: any) => setAnchorSimNetAmountEl(event.currentTarget);

	const FormDivider = useMemo(
		() => () =>
			(
				<Grid item {...sizes[12]}>
					<Divider />
				</Grid>
			),
		[]
	);

	//Get name of career job role to insert in levels table and check if current job role has any level with place != undefined, if so adds the column
	useEffect(() => {
		const jobRole = selectOptions.careerJobRole.find(e => e.id == watch('careerJobRole'));
		setCurrentJobRoleName(jobRole?.label);

		const jobRoleLevelsHavePlace = selectOptions.careerJobRoleLevel.find(e => e.place != undefined);
		setCurrentJobRoleHasPlace(jobRoleLevelsHavePlace ? true : false);
	}, [watch('careerJobRole')]);

	// This useEffect checks the maritalStatus and defines the numbers of holders as 1 if the maritalStatus is equal to "single, divorced or widower"
	// If the maritalStatus is equal to "Married" the user can choose between 1 or 2 holders
	useEffect(() => {
		if (watch('maritalStatus') === 'Married') {
			setHolderStatusOptions([
				{ id: '1', label: 'Single Holder' },
				{ id: '2', label: 'Two Holders' }
			]);
		} else {
			setHolderStatusOptions([{ id: '1', label: 'Single Holder' }]);
			setValue('holdersNumber', '1');
		}
	}, [watch('maritalStatus')]);

	useEffect(() => {
		setPTCompany(showTaxZone());
	}, []);

	useEffect(() => {
		updateBudget();
	}, []);

	const updateBudget = () => {
		let rate = watch('hourlyRate');
		setBudgetValue(rate * 8 * 18);
		if (budgetValue !== 0) {
			setValue('budget', budgetValue);
		}
	};

	useEffect(() => {
		updateBonusSalary();
	}, []);

	const [bonusSalaryValue, setBonusSalaryValue] = useState(0);

	const updateBonusSalary = () => {
		let baseValue = watch('baseValue');
		let isPayBonusSalary = watch('payBonusSalary');
		setBonusSalaryValue(baseValue);
		if (isPayBonusSalary) {
			setValue('bonusSalary', bonusSalaryValue !== 0 ? bonusSalaryValue : watch('bonusSalary') || baseValue);
		}
	};

	const currencyFormat = num => {
		return (
			Number(num)
				.toFixed(2)
				.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
				.replace('.', ',')
				.replace(/\./g, '.') // Replace all dots with commas
				.replace(',', '.') + ' €' // Replace all commas with dots // Replace dot with comma
		);
	};

	useEffect(() => {
		if (watch('payBonusSalary')) {
			setValue(
				'bonusSalary',
				bonusSalaryValue !== 0 ? bonusSalaryValue : watch('bonusSalary') || watch('baseValue')
			);
		}
	}, [watch('payBonusSalary')]);

	//Checks if startDate os filled and performs updateIRSTax if so
	const checkStartDateUpdateIRSTax = () => {
		if (watch('startDate') == '' || watch('startDate') == null) {
			addNotification('danger', 'Start date is necessary to update IRS Tax.', 5);
		} else {
			updateIRSTax(watch());
		}
	};

	//Checks if the company is portuguese in order to show a select
	const showTaxZone = () => {
		//Check if is a Collaboration Proposal
		if (type === PROPOSAL) {
			for (let company in newProposal.companies) {
				//Gets the current selected company

				if (getValues('company') === newProposal.companies[company].searchKey) {
					//checks if the current company is portuguese
					setSurtaxZoneList(
						newProposal.companies[company].surtaxZones?.map(e => {
							return { id: e.id, label: e.name };
						})
					);
					if (newProposal.companies[company].erpCountryCode === '281') {
						//If the taxZone is different from the default values it does not change
						if (getValues('taxZone') !== '' && getValues('taxZone') !== 'PT') return true;
						//Changes the value to default and returns true
						setValue('taxZone', 'PT');
						return true;
					}
				}
			}
		}
		//Check if is a New Employee
		if (type === EMPLOYEE) {
			for (let company in newEmployee.companies) {
				//Gets the current selected company
				if (getValues('company') === newEmployee.companies[company].searchKey) {
					setSurtaxZoneList(
						newEmployee?.companies[company]?.surtaxZones?.map(e => {
							return { id: e.id, label: e.name };
						})
					);
					//checks if the current company is portuguese
					if (newEmployee.companies[company].erpCountryCode === '281') {
						//If the taxZone is different from the default values it does not change
						if (getValues('taxZone') !== '' && getValues('taxZone') !== 'PT') return true;
						//Changes the value to default and returns true
						setValue('taxZone', 'PT');
						return true;
					}
				}
			}
		}
		//Check if is a Cost Salary Category
		if (type === CSC) {
			setSurtaxZoneList(
				newEmployeeSalary?.company?.surtaxZones?.map(e => {
					return { id: e.id, label: e.name };
				})
			);
			//checks if the current company is portuguese
			if (newEmployeeSalary.company.erpCountryCode === '281') {
				//If the taxZone is different from the default values it does not change
				if (getValues('taxZone') !== '' && getValues('taxZone') !== 'PT') return true;
				//Changes the value to default and returns true
				setValue('taxZone', 'PT');
				return true;
			}
		}
		//Check if is a Collaboration Type
		if (type === COLLABTYPE) {
			setSurtaxZoneList(
				employee?.company?.surtaxZones?.map(e => {
					return { id: e.id, label: e.name };
				})
			);
			//checks if the current company is portuguese
			if (employee.company.erpCountryCode === '281') {
				//If the taxZone is different from the default values it does not change
				if (getValues('taxZone') !== '' && getValues('taxZone') !== 'PT') return true;
				//Changes the value to default and returns true
				setValue('taxZone', 'PT');
				return true;
			}
		}

		//If none of the above verifications are true than its not a portuguese company and gives undefined value to taxZone and returns false
		setValue('taxZone', '');
		return false;
	};
	const taxZoneSelect = [
		{ id: 'PT', label: 'PT' },
		{ id: 'PT-AC', label: 'PT-AC' },
		{ id: 'PT-MA', label: 'PT-MA' }
	];

	//Used when job role levels don't have place variable
	let columns = [
		{ label: 'Career Level', id: 'careerLevel' },
		{ label: 'Career Sub Level', id: 'careerSubLevel' },
		{
			label: 'Base Amount',
			id: 'baseAmount',
			align: 'left',
			width: '10%',
			format: row =>
				row?.baseAmount !== 0 && row?.preferable == false ? (
					<Numeric readOnly money value={row?.baseAmount.toFixed(2)} />
				) : (
					<label>{currencyFormat(row?.baseAmount)}</label>
				)
		},
		{
			label: (
				<>
					<IconButton style={{ color: 'rgba(0, 0, 0, 0.54)' }} onClick={handleClickNetAmount}>
						<InfoIcon />
					</IconButton>
					Monthly Net Amount
					<Popover
						id="netMonthlyCard"
						open={Boolean(anchorNetAmountEl)}
						anchorEl={anchorNetAmountEl}
						onClose={() => setAnchorNetAmountEl(null)}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}>
						<div style={{ padding: '15px' }}>
							<table>
								<tbody>
									<tr>
										<td>Standard net amount, considering a single person without dependents</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Popover>
				</>
			),
			id: 'monthlyNet',
			align: 'left',
			width: '17%',
			format: row =>
				row?.monthlyNet !== 0 && row?.preferable == false ? (
					<Grid container direction="row" className={classes.fieldWithInfoToolTip}>
						<Grid item>
							<Numeric readOnly money value={row?.monthlyNet} />{' '}
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffMonthlyNet) < 0 ? 'red' : 'green'
									}}>
									({row.diffMonthlyNet.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				) : (
					<Grid container direction="row" className={classes.fieldWithInfoToolTip}>
						<Grid item xs={12}>
							<label>{currencyFormat(row.monthlyNet)}</label>
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffMonthlyNet) < 0 ? 'red' : 'green',
										fontWeight: 'normal'
									}}>
									({row.diffMonthlyNet.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				)
		},
		{
			label: 'Annual Cost',
			id: 'annualCost',
			align: 'left',
			width: '10%',
			format: row =>
				row?.annualCost !== 0 && row?.preferable == false ? (
					<Grid container direction="row">
						<Grid item>
							<Numeric readOnly money value={row?.annualCost} />{' '}
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffTotalCost) < 0 ? 'red' : 'green'
									}}>
									({row.diffTotalCost.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				) : (
					<Grid container direction="row">
						<Grid item xs={12}>
							<label>{currencyFormat(row.annualCost)}</label>
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffTotalCost) < 0 ? 'red' : 'green',
										fontWeight: 'normal'
									}}>
									({row.diffTotalCost.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				)
		},
		...(isContractor === 'Employee'
			? [
					{
						label: (
							<>
								<IconButton style={{ color: 'rgba(0, 0, 0, 0.54)' }} onClick={handleClickSimNetAmount}>
									<InfoIcon />
								</IconButton>
								Monthly Net Amount (Simulation)
								<Popover
									id="simNetMonthlyCard"
									open={Boolean(anchorSimNetAmountEl)}
									anchorEl={anchorSimNetAmountEl}
									onClose={() => setAnchorSimNetAmountEl(null)}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'center'
									}}
									transformOrigin={{
										vertical: 'bottom',
										horizontal: 'center'
									}}>
									<div style={{ padding: '15px' }}>
										<table>
											<tbody>
												<tr>
													<td>
														Real net amount, considering employee fiscal context (marital
														status, holders, and number of dependents)
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Popover>
							</>
						),
						id: 'monthlyNetSimulation',
						align: 'left',
						width: '23%',
						format: row => (
							<Grid container direction="row" className={classes.fieldWithInfoToolTip}>
								{row.monthlyNetSimulation}
							</Grid>
						)
					}
			  ]
			: [])
	];

	//Used when job role levels have place variable
	const columnsWithPlace = [
		{ label: 'Career Level', id: 'careerLevel' },
		{ label: 'Career Sub Level', id: 'careerSubLevel' },
		{ label: 'Place', id: 'place' },
		{
			label: 'Base Amount',
			id: 'baseAmount',
			align: 'left',
			width: '10%',
			format: row =>
				row?.baseAmount !== 0 && row?.preferable == false ? (
					<Numeric readOnly money value={row?.baseAmount.toFixed(2)} />
				) : (
					<label>{currencyFormat(row?.baseAmount)}</label>
				)
		},
		{
			label: (
				<>
					<IconButton style={{ color: 'rgba(0, 0, 0, 0.54)' }} onClick={handleClickNetAmount}>
						<InfoIcon />
					</IconButton>
					Monthly Net Amount
					<Popover
						id="netMonthlyCard"
						open={Boolean(anchorNetAmountEl)}
						anchorEl={anchorNetAmountEl}
						onClose={() => setAnchorNetAmountEl(null)}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}>
						<div style={{ padding: '15px' }}>
							<table>
								<tbody>
									<tr>
										<td>Standard net amount, considering a single person without dependents</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Popover>
				</>
			),
			id: 'monthlyNet',
			align: 'left',
			width: '17%',
			format: row =>
				row?.monthlyNet !== 0 && row?.preferable == false ? (
					<Grid container direction="row">
						<Grid item>
							<Numeric readOnly money value={row?.monthlyNet} />{' '}
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffMonthlyNet) < 0 ? 'red' : 'green'
									}}>
									({row.diffMonthlyNet.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				) : (
					<Grid container direction="row">
						<Grid item xs={12}>
							<label>{currencyFormat(row.monthlyNet)}</label>
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffMonthlyNet) < 0 ? 'red' : 'green',
										fontWeight: 'normal'
									}}>
									({row.diffMonthlyNet.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				)
		},
		{
			label: 'Annual Cost',
			id: 'annualCost',
			align: 'left',
			width: '10%',
			format: row =>
				row?.annualCost !== 0 && row?.preferable == false ? (
					<Grid container direction="row" className={classes.fieldWithInfoToolTip}>
						<Grid item>
							<Numeric readOnly money value={row?.annualCost} />{' '}
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffTotalCost) < 0 ? 'red' : 'green'
									}}>
									({row.diffTotalCost.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				) : (
					<Grid container direction="row" className={classes.fieldWithInfoToolTip}>
						<Grid item xs={12}>
							<label>{currencyFormat(row.annualCost)}</label>
						</Grid>
						{type === CSC && (
							<Grid item>
								<label
									style={{
										color: Number(row.diffTotalCost) < 0 ? 'red' : 'green',
										fontWeight: 'normal'
									}}>
									({row.diffTotalCost.toFixed(2)}
									%)
								</label>
							</Grid>
						)}
					</Grid>
				)
		},
		...(isContractor === 'Employee'
			? [
					{
						label: (
							<>
								<IconButton style={{ color: 'rgba(0, 0, 0, 0.54)' }} onClick={handleClickSimNetAmount}>
									<InfoIcon />
								</IconButton>
								Monthly Net Amount (Simulation)
								<Popover
									id="simNetMonthlyCard"
									open={Boolean(anchorSimNetAmountEl)}
									anchorEl={anchorSimNetAmountEl}
									onClose={() => setAnchorSimNetAmountEl(null)}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'center'
									}}
									transformOrigin={{
										vertical: 'bottom',
										horizontal: 'center'
									}}>
									<div style={{ padding: '15px' }}>
										<table>
											<tbody>
												<tr>
													<td>
														Real net amount, considering employee fiscal context (marital
														status, holders, and number of dependents)
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Popover>
							</>
						),
						id: 'monthlyNetSimulation',
						align: 'left',
						width: '23%',
						format: row => (
							<Grid container direction="row" className={classes.fieldWithInfoToolTip}>
								{row.monthlyNetSimulation}
							</Grid>
						)
					}
			  ]
			: [])
	];

	const importValuesColumns = [
		{ label: 'Name', id: 'name' },
		{
			label: 'Base Value',
			id: 'xphrBaseIncome',
			format: (row: { xphrBaseIncome: number }) =>
				row?.xphrBaseIncome !== 0 ? (
					<Numeric readOnly money value={row?.xphrBaseIncome} />
				) : (
					<InfoField money disabled value={`${row.xphrBaseIncome}€`} />
				)
		},
		{
			label: 'Iht Value',
			id: 'xphrWorkExempionValue',
			format: (row: { xphrWorkExempionValue: number }) =>
				row?.xphrWorkExempionValue !== 0 ? (
					<Numeric readOnly money value={row?.xphrWorkExempionValue} />
				) : (
					<InfoField money disabled value={`${row.xphrWorkExempionValue}€`} />
				)
		},
		{
			label: 'Other Expenses',
			id: 'xphrOtherBenefits',
			format: (row: { xphrOtherBenefits: number }) =>
				row?.xphrOtherBenefits !== 0 ? (
					<Numeric readOnly money value={row?.xphrOtherBenefits} />
				) : (
					<InfoField money disabled value={`${row.xphrOtherBenefits}€`} />
				)
		},
		{
			label: 'Life Insurance',
			id: 'xphrLifeInsuranceValue',
			format: (row: { xphrLifeInsuranceValue: number }) =>
				row?.xphrLifeInsuranceValue !== 0 ? (
					<Numeric readOnly money value={row?.xphrLifeInsuranceValue} />
				) : (
					<InfoField money disabled value={`${row.xphrLifeInsuranceValue}€`} />
				)
		},
		{
			label: 'Monthly Net (Estimated Value)',
			id: 'xphrMonthlyNetAmount',
			format: (row: { xphrMonthlyNetAmount: number }) =>
				row?.xphrMonthlyNetAmount !== 0 ? (
					<Numeric readOnly money value={row?.xphrMonthlyNetAmount} />
				) : (
					<InfoField money disabled value={`${row.xphrMonthlyNetAmount}€`} />
				)
		},
		{
			label: 'Annual Gross Value',
			id: 'xphrAnnualGrossValue',
			format: (row: { xphrAnnualGrossValue: number }) =>
				row?.xphrAnnualGrossValue !== 0 ? (
					<Numeric readOnly money value={row?.xphrAnnualGrossValue} />
				) : (
					<InfoField money disabled value={`${row.xphrAnnualGrossValue}€`} />
				)
		},
		{
			label: 'Annual Gross Cost',
			id: 'xphrAnnualGrossAmount',
			format: (row: { xphrAnnualGrossAmount: number }) =>
				row?.xphrAnnualGrossAmount !== 0 ? (
					<Numeric readOnly money value={row?.xphrAnnualGrossAmount} />
				) : (
					<InfoField money disabled value={`${row.xphrAnnualGrossAmount}€`} />
				)
		}
	];

	const additionalActions: ITableAction<any>[] = [
		{
			id: 'teste',
			type: '',
			render: () => true,
			options: [
				{
					id: '98',
					label: 'Apply Level & Values',
					onClick: row => {
						if (type === CSC) checkPercentage(row);
						setValue('baseValue', row.baseAmount);
						setValue('additionalExpenses', row.otherExpenses);
						setAddExpensesWhenNursery0(row.otherExpenses);
						setValue('lifeInsurance', row.lifeInsurance);
						initFinancialValues(watch());
						checkStartDateUpdateIRSTax();
						getAnnualGrossValue(watch());
						handleSelectHelper('careerJobRoleLevel', row.id);
						dispatch(setProposalSummary(null));
						setSimulatedRow(null);
						setSimulatedRows([]);
					}
				},

				{
					id: '100',
					label: 'Apply Values Only',
					onClick: row => {
						if (type === CSC) checkPercentage(row);
						setValue('baseValue', row.baseAmount);
						setValue('additionalExpenses', row.otherExpenses);
						setAddExpensesWhenNursery0(row.otherExpenses);
						setValue('lifeInsurance', row.lifeInsurance);
						initFinancialValues(watch());
						checkStartDateUpdateIRSTax();
						getAnnualGrossValue(watch());
						dispatch(setProposalSummary(null));
						setSimulatedRow(null);
						setSimulatedRows([]);
					}
				},
				{
					id: '101',
					label: 'Simulate',
					onClick: row => {
						//Monthly Net Amount and Annual Cost - Simulation
						if (type === CSC) checkPercentage(row);
						const initialFormPayload = watch();
						setValue('baseValue', row.baseAmount);
						setValue('additionalExpenses', row.otherExpenses);
						setAddExpensesWhenNursery0(row.otherExpenses);
						setValue('lifeInsurance', row.lifeInsurance);
						setValue('careerJobRoleLevel', row.id);
						initFinancialValues(watch());
						checkStartDateUpdateIRSTax();
						getAnnualGrossValue(watch());
						simulate(row, initialFormPayload);
					},
					disabled: row => isProposalSummaryloading
				}
			]
		}
	];
	const importAdditionalActions: ITableAction<any>[] = [
		{
			id: 'applyFinancialValues',
			type: '',
			render: () => true,
			options: [
				{
					id: '98',
					label: 'Apply Financial Values',
					onClick: row => {
						if (type === CSC) {
							setValue('baseValue', row.xphrBaseIncome);
							setValue('additionalExpenses', row.xphrOtherBenefits);
							setAddExpensesWhenNursery0(row.xphrOtherBenefits);
							setValue('lifeInsurance', row.xphrLifeInsuranceValue);
							initFinancialValues(watch());
							getAnnualGrossValue(watch());
							checkStartDateUpdateIRSTax();
						}
					}
				}
			]
		}
	];

	// JOB ROLE LEVEL - Table data
	useEffect(() => {
		// Update simulatedRows by creating a new array
		if (simulatedRow) {
			const updatedSimulatedRows = [...simulatedRows];
			const index = updatedSimulatedRows.findIndex(row => row.id == simulatedRow.id);

			// If found, update the row; otherwise, add it
			if (index !== -1) {
				updatedSimulatedRows[index] = {
					...updatedSimulatedRows[index],
					monthlyNetSimulation: proposalSummary?.monthlyNetValue
				};
			} else {
				updatedSimulatedRows.push({
					id: simulatedRow.id,
					monthlyNetSimulation: proposalSummary?.monthlyNetValue
				});
			}

			setSimulatedRows(updatedSimulatedRows);
		}
		setTableData(
			formFields.formFieldsConfig.formType == 'Cost Salary Category'
				? selectOptions.careerJobRoleLevel.map(e => {
						if (simulatedRow && e.id === simulatedRow.id) {
							return {
								...e,
								diffMonthlyNet:
									((Number(e.monthlyNet) -
										Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)) /
										Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)) *
									100,
								diffTotalCost:
									((Number(e.annualCost) -
										Number(newEmployeeSalary?.currentCSC.xphrAnnualGrossCostPriz)) /
										Number(newEmployeeSalary?.currentCSC.xphrAnnualGrossCostPriz)) *
									100,
								monthlyNetSimulation: proposalSummary ? (
									(
										<>
											<Numeric readOnly money value={proposalSummary?.monthlyNetValue} />
											{type === CSC && (
												<label
													style={{
														color:
															Number(proposalSummary?.monthlyNetValue) -
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																) <
															0
																? 'red'
																: 'green',
														textWrap: 'nowrap'
													}}>
													{'('}
													{(
														((Number(proposalSummary?.monthlyNetValue) -
															Number(
																newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
															)) /
															Number(
																newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
															)) *
														100
													).toFixed(2)}
													{'% ; '}
													{formatCurrency(
														Number(proposalSummary?.monthlyNetValue) -
															Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)
													)}
													{')'}
												</label>
											)}
										</>
									) || (
										<>
											<Numeric readOnly money value={'0'} />; 00,00% ; 00,00€
										</>
									)
								) : (
									<CircularProgress color="primary" />
								)
							};
						} else {
							const simulatedData = simulatedRows.find(row => row.id == e.id);
							return simulatedData
								? {
										...e,
										diffMonthlyNet:
											((Number(e.monthlyNet) -
												Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)) /
												Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)) *
											100,
										diffTotalCost:
											((Number(e.annualCost) -
												Number(newEmployeeSalary?.currentCSC.xphrAnnualGrossCostPriz)) /
												Number(newEmployeeSalary?.currentCSC.xphrAnnualGrossCostPriz)) *
											100,
										monthlyNetSimulation: (
											<>
												<Numeric readOnly money value={simulatedData?.monthlyNetSimulation} />
												{type === CSC && (
													<label
														style={{
															color:
																Number(simulatedData?.monthlyNetSimulation) -
																	Number(
																		newEmployeeSalary?.currentCSC
																			.xphrMonthlyNetAmount
																	) <
																0
																	? 'red'
																	: 'green',
															textWrap: 'nowrap'
														}}>
														{'('}
														{(
															((Number(simulatedData?.monthlyNetSimulation) -
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																)) /
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																)) *
															100
														).toFixed(2)}
														{'% ; '}
														{formatCurrency(
															Number(simulatedData?.monthlyNetSimulation) -
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																)
														)}
														{')'}
													</label>
												)}
											</>
										) || (
											<>
												<Numeric readOnly money value={'0'} />; 00,00% ; 00,00€
											</>
										)
								  }
								: {
										...e,
										diffMonthlyNet:
											((Number(e.monthlyNet) -
												Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)) /
												Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)) *
											100,
										diffTotalCost:
											((Number(e.annualCost) -
												Number(newEmployeeSalary?.currentCSC.xphrAnnualGrossCostPriz)) /
												Number(newEmployeeSalary?.currentCSC.xphrAnnualGrossCostPriz)) *
											100
								  };
						}
				  })
				: selectOptions.careerJobRoleLevel.map(e => {
						const simulatedData = simulatedRows.find(row => row.id == e.id);
						return simulatedRow && e.id === simulatedRow.id
							? {
									...e,
									monthlyNetSimulation: proposalSummary ? (
										(
											<>
												<Numeric readOnly money value={proposalSummary?.monthlyNetValue} />
												{type === CSC && (
													<label
														style={{
															color:
																Number(proposalSummary?.monthlyNetValue) -
																	Number(
																		newEmployeeSalary?.currentCSC
																			.xphrMonthlyNetAmount
																	) <
																0
																	? 'red'
																	: 'green',
															textWrap: 'nowrap'
														}}>
														{'('}
														{(
															((Number(proposalSummary?.monthlyNetValue) -
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																)) /
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																)) *
															100
														).toFixed(2)}
														{'% ; '}
														{formatCurrency(
															Number(proposalSummary?.monthlyNetValue) -
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																)
														)}
														{')'}
													</label>
												)}
											</>
										) || (
											<>
												<Numeric readOnly money value={'0'} />; 00,00% ; 00,00€
											</>
										)
									) : (
										<CircularProgress color="primary" />
									)
							  }
							: simulatedData
							? {
									...e,
									monthlyNetSimulation: (
										<>
											<Numeric readOnly money value={simulatedData?.monthlyNetSimulation} />
											{type === CSC && (
												<label
													style={{
														color:
															Number(simulatedData?.monthlyNetSimulation) -
																Number(
																	newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
																) <
															0
																? 'red'
																: 'green',
														textWrap: 'nowrap'
													}}>
													{'('}
													{(
														((Number(simulatedData?.monthlyNetSimulation) -
															Number(
																newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
															)) /
															Number(
																newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount
															)) *
														100
													).toFixed(2)}
													{'% ; '}
													{formatCurrency(
														Number(simulatedData?.monthlyNetSimulation) -
															Number(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)
													)}
													{')'}
												</label>
											)}
										</>
									) || (
										<>
											{' '}
											<Numeric readOnly money value={'0'} />; 00,00% ; 00,00€
										</>
									)
							  }
							: { ...e };
				  })
		);
	}, [selectOptions.careerJobRoleLevel, proposalSummary, simulatedRow]);

	useEffect(() => {
		if (watch('manuallyEditFinancialValues')) {
			const currentValues = { ...getValues() };
			delete currentValues.manuallyEditFinancialValues; // Exclude this field
			setSnapshot(currentValues);
		} else if (snapshot) {
			// Restore the snapshot when checkbox is unselected
			Object.keys(snapshot).forEach(key => setValue(key, snapshot[key]));
		}
	}, [watch('manuallyEditFinancialValues')]);

	//Monthly Net Amount and Annual Cost - Simulation
	const simulate = (row: any, initialFormPayload: any) => {
		const formPayload = watch();
		dispatch(setProposalSummary(null));
		setSimulatedRow(row);
		getProposalSummary({
			...formPayload,
			workScheduleExemptionValue: formPayload.ihtValue,
			prizeValue: formPayload.prizeBonus,
			formFields: formFields.formFieldsConfig,
			// Set default values for missing fields to ensure calculation can proceed without errors
			name: formPayload.name || 'name',
			email: formPayload.email || 'default.email@xpand-it.com',
			careerManager: formPayload.careerManager || '94B5BA3DFD24402684ADFD18D0221321',
			contractType: formPayload.contractType || '10',
			contractPosition: formPayload.contractPosition || '8A948ACA32FB196A01331154C89C0100',
			primaryOffice: formPayload.primaryOffice || 'VC',
			workingOffice: formPayload.workingOffice || 'VC',
			employeeManager: formPayload.employeeManager || '575',
			holidayType: formPayload.holidayType || 'C85B4353-9F26-49F5-A0A9-B78698A76C0D',
			referralType: formPayload.referralType || 'Direct',
			username: formPayload.username || 'username',
			timeOffApprovalType: formPayload.timeOffApprovalType || '307',
			timeoffPolicy: formPayload.timeoffPolicy || '99D08A8F-AD40-490D-A0B5-2CFFC8D2769B',
			vacationDays: formPayload.vacationDays || 0
		});
		reset(initialFormPayload);
	};

	// Filters only the data with an existing currentCsc
	const importData = selectOptions?.allEmployeesCSCs
		?.map(obj => ({
			...obj,
			...obj.currentCsc
		}))
		?.filter(obj => obj.currentCsc);

	const showWarningMessage = selectOptions?.showWarningJobLevels;
	const warningMessage = `Levels shown are only the ones with Monthly Net Value equal or higher than Employee's
	current level's`;

	const checkPercentage = row => {
		const percentage =
			type === CSC && initialFormData.currentCSC
				? Number(row.annualCost) / Number(initialFormData.currentCSC.xphrAnnualGrossCostPriz)
				: Number(row.annualCost) / Number(initialFormData.proposalSummary.annualGrossCostWithPrize);
		if (Number(percentage.toFixed(2)) >= 1.01) {
			addNotification('warning', 'The selected level Annual Cost is more than 1% higher than current level', 5);
		}
	};
	const prizeBonus = watch('prizeBonus');
	const monthsApplicable = watch('monthsApplicable');
	const prizeBonusValueToComponent =
		(monthsApplicable && prizeBonus && (Number(monthsApplicable) * Number(prizeBonus)) / 12) || null;
	const showPrizeBonus = prizeBonus && Number(prizeBonus) !== 0;
	const disabled = watch('disabled') ? 'Yes' : 'No';
	const tsuExemption = watch('tsuExemption') ? 'Yes' : 'No';
	const dependentsNumber = watch('dependentsNumber') == 0 ? '0' : watch('dependentsNumber');
	const taxes = (
		<>
			{isContractor !== 'Contractor' ? (
				<GroupCollapse
					viewExpanded={viewExpanded}
					collapse={{
						id: 'taxes',
						label: 'Taxes'
					}}
					actions={
						!editMode && type == CSC
							? [
									{
										id: 'edit',
										label: 'Edit',
										icon: <Edit />,
										onClick: () => setEditMode(true)
									}
							  ]
							: []
					}>
					{editMode && (
						<>
							{/* NISS */}
							{formFields.formFieldsConfig.socialSecurityNumber && (
								<Grid item {...sizes[6]}>
									<Input
										required
										label="NISS"
										name="socialSecurityNumber"
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{formFields?.formFieldsConfig?.pensionFund && (
								<Grid item {...sizes[6]}>
									<Input
										name="pensionFundNumber"
										label="Pension Fund Number"
										required
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{/* MARITAL STATUS */}
							{formFields.formFieldsConfig.maritalStatus && (
								<Grid item {...sizes[6]}>
									<Select
										required
										name="maritalStatus"
										label="MARITAL STATUS"
										options={selectOptions?.maritalStatus || []}
										additionalOnChange={() => {
											if (watch('company') !== 'HR') {
												checkStartDateUpdateIRSTax();
											}
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{/* DISABLED CHECKBOX */}
							{formFields.formFieldsConfig.disabled && (
								<Grid item {...sizes[6]}>
									<Checkbox
										color="primary"
										name="disabled"
										additionalOnChange={() => {
											checkStartDateUpdateIRSTax();
										}}
										label="DISABLED?"
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{/* HOLDERS NUMBERS */}
							{formFields.formFieldsConfig.holders && (
								<Grid item {...sizes[6]}>
									<Select
										required
										name="holdersNumber"
										label="HOLDERS"
										additionalOnChange={() => {
											if (watch('company') !== 'HR') {
												checkStartDateUpdateIRSTax();
											}
										}}
										options={holderStatusOptions}
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{/* TSU EXEMPTION  */}
							{formFields.formFieldsConfig.socialSecurityExemption && (
								<Grid item {...sizes[6]}>
									<Checkbox
										color="primary"
										name="tsuExemption"
										label="SOCIAL SECURITY EXEMPTION"
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{/* DEPENDENTS NUMBERS */}
							{formFields.formFieldsConfig.numberDependents && (
								<Grid item {...sizes[6]}>
									<Numeric
										name="dependentsNumber"
										label="NUMBER OF DEPENDENTS"
										minimumValue="0"
										maximumValue="10"
										additionalOnBlur={() => {
											if (watch('company') !== 'HR') {
												checkStartDateUpdateIRSTax();
											}
										}}
										required
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{formFields.formFieldsConfig.taxZone && (
								<Grid item {...sizes[6]}>
									<Select
										required
										name="taxZone"
										label="TAX ZONE"
										options={taxZoneSelect}
										additionalOnChange={() => {
											checkStartDateUpdateIRSTax();
										}}
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{/* {formFields.formFieldsConfig.surtaxZone && (
								<Grid item {...sizes[6]}>
									<Select
										required
										name="surtaxZone"
										label="SURTAX ZONE"
										options={surtaxZoneList}
										control={control}
										errors={errors}
									/>
								</Grid>
							)} */}
							{formFields.formFieldsConfig.employmentStimulusMeasure && (
								<Grid item {...sizes[6]}>
									<Checkbox
										color="primary"
										name="estimuloMedida"
										label="EMPLOYMENT STIMULUS MEASURE"
										control={control}
										errors={errors}
									/>
								</Grid>
							)}
							{formFields.formFieldsConfig.companySocialSecurityPercentage && (
								<Grid item {...sizes[6]}>
									<InfoField
										name="companySocSec"
										label="Company Soc. Sec. (%)"
										getValues={getValues}
									/>
								</Grid>
							)}
							{formFields.formFieldsConfig.formType == 'Cost Salary Category' && (
								<Grid item {...sizes[6]}>
									<Button color="primary" variant="contained" onClick={() => setEditMode(false)}>
										Close Edit
									</Button>
								</Grid>
							)}
						</>
					)}
					{!editMode && formFields.formFieldsConfig.formType == 'Cost Salary Category' && (
						<>
							{/* NISS */}
							{formFields.formFieldsConfig.socialSecurityNumber && (
								<Grid item {...sizes[6]}>
									<InfoField fontSize="lg" label="NISS" value={watch('socialSecurityNumber')} />
								</Grid>
							)}
							{/* HOLDERS NUMBERS */}
							{formFields.formFieldsConfig.holders && (
								<Grid item {...sizes[6]}>
									<InfoField
										fontSize="lg"
										label="HOLDERS"
										value={holderStatusOptions[watch('holdersNumber') - 1].label}
									/>
								</Grid>
							)}
							{/* DISABLED CHECKBOX */}
							{formFields.formFieldsConfig.disabled && (
								<Grid item {...sizes[6]}>
									<InfoField fontSize="lg" label="DISABLED?" value={disabled} />
								</Grid>
							)}
							{/* MARITAL STATUS */}
							{formFields.formFieldsConfig.maritalStatus && (
								<Grid item {...sizes[6]}>
									<InfoField fontSize="lg" label="MARITAL STATUS" value={watch('maritalStatus')} />
								</Grid>
							)}
							{/* TSU EXEMPTION  */}
							{formFields.formFieldsConfig.socialSecurityExemption && (
								<Grid item {...sizes[6]}>
									<InfoField fontSize="lg" label="SOCIAL SECURITY EXEMPTION" value={tsuExemption} />
								</Grid>
							)}
							{/* DEPENDENTS NUMBERS */}
							{formFields.formFieldsConfig.numberDependents && (
								<Grid item {...sizes[6]}>
									<InfoField fontSize="lg" label="NUMBER OF DEPENDENTS" value={dependentsNumber} />
								</Grid>
							)}
							{formFields.formFieldsConfig.taxZone && (
								<Grid item {...sizes[6]}>
									<InfoField fontSize="lg" label="TAX ZONE" value={watch('taxZone')} />
								</Grid>
							)}
							{formFields.formFieldsConfig.employmentStimulusMeasure && (
								<Grid item {...sizes[6]}>
									<InfoField
										fontSize="lg"
										label="EMPLOYMENT STIMULUS MEASURE"
										value={watch('estimuloMedida')}
									/>
								</Grid>
							)}
							{formFields.formFieldsConfig.companySocialSecurityPercentage && (
								<Grid item {...sizes[6]}>
									<InfoField
										name="companySocSec"
										label="Company Soc. Sec. (%)"
										getValues={getValues}
									/>
								</Grid>
							)}
						</>
					)}
				</GroupCollapse>
			) : (
				''
			)}
		</>
	);
	return (
		<Grid
			container
			spacing={2}
			justifyContent="center"
			style={{
				paddingBottom: '20px'
			}}>
			<div className={classes.actions}>
				<Button
					key="viewBtnController"
					size="large"
					variant="text"
					startIcon={<UnfoldMore className={classes.iconGrayed} />}
					onClick={() => {
						setViewExpanded(!viewExpanded);
					}}>
					<Typography variant="body1" className={classes.iconGrayed}>
						{viewExpanded ? 'Collapse all' : 'Expand all'}
					</Typography>
				</Button>
			</div>
			{/* USER DATA */}
			{formFields.formFieldsConfig.formType == 'Cost Salary Category' && (
				<GroupCollapse
					showContent={false}
					collapse={{
						id: 'userData',
						label: 'LOAD FINANCIAL VALUES FROM ANOTHER EMPLOYEE'
					}}>
					<Grid item {...sizes[6]}>
						<SearchableSelect
							required
							name="example"
							label="Select employee to load"
							additionalActions={importAdditionalActions}
							tableData={importData}
							disableDefaultAction
							columns={importValuesColumns}
							control={control}
							errors={errors}
							setValue={setValue as any}
							dialogTitle="Select employee to load"
							watch={watch()}
						/>
					</Grid>
				</GroupCollapse>
			)}
			{/* CAREER INFO */}
			<GroupCollapse
				viewExpanded={viewExpanded}
				collapse={{
					id: 'careerInfo',
					label: 'Career Info'
				}}>
				{/* CAREER NAME */}
				{formFields.formFieldsConfig.careerName && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="careerPosition"
							label="Career Name"
							{...(selectOptions.careerPosition === undefined && {
								disabled: true,
								helperText: 'No careers for the department selected, please select other department'
							})}
							options={selectOptions.careerPosition || []}
							additionalOnChange={(item: any) => {
								handleSelectHelper('careerPosition', item.value);
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* CAREER JOB ROLE */}
				{formFields.formFieldsConfig.careerJobRole && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="careerJobRole"
							label="Career Job Role"
							disabled={selectOptions.careerJobRole.length === 0 || watch('careerPosition') == ''}
							options={selectOptions.careerJobRole || []}
							additionalOnChange={(item: any) => {
								handleSelectHelper('careerJobRole', item.value);
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* CAREER MANAGER */}
				{formFields.formFieldsConfig.careerManager && (
					<Grid item {...sizes[6]}>
						<Select
							required
							name="careerManager"
							label="Career Manager"
							options={selectOptions.careerManagers || []}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* CAREER JOB ROLE LEVEL */}
				{formFields.formFieldsConfig.careerJobRoleLevel && (
					<Grid item {...sizes[6]}>
						<SearchableSelect
							required
							disabled={tableData.length == 0 || watch('careerPosition') == ''}
							name="careerJobRoleLevel"
							label="Career Job Role Level"
							additionalActions={isContractor === 'Employee' ? additionalActions : []}
							additionalOnSelect={handleSelectHelper}
							tableData={tableData}
							columns={currentJobRoleHasPlace ? columnsWithPlace : columns}
							control={control}
							errors={errors}
							setValue={setValue as any}
							mainOptionLabel="Apply Level Only"
							dialogTitle={
								currentJobRoleName
									? 'Career Job Role Levels for ' + currentJobRoleName
									: 'Career Job Role Levels'
							}
							watch={watch()}
							showWarningMessage={showWarningMessage}
							warningMessage={warningMessage}
							showSubtitles={true}
							handleSearch={false}
							handleFilters={{ filtersValue, setFiltersValue }}
							tableFilterSearch={tableFilterSearch}
							filters={filters}
							filtersRows={filtersRows}
							formType={type}
							monthlyNetAmount={formatCurrency(newEmployeeSalary?.currentCSC.xphrMonthlyNetAmount)}
							annualGrossCostPriz={formatCurrency(newEmployeeSalary?.currentCSC.xphrAnnualGrossCostPriz)}
							annualPrize={
								newEmployeeSalary?.currentCSC.xphrPrizeValue > 0
									? formatCurrency(newEmployeeSalary?.currentCSC.xphrPrizeValue)
									: null
							}
							preventPageReset={isProposalSummaryloading}
						/>
					</Grid>
				)}
			</GroupCollapse>
			{/* TAXES */}
			{formFields.formFieldsConfig.formType != 'Cost Salary Category' && taxes}
			{/* GENERAL AMOUNTS */}
			<GroupCollapse
				viewExpanded={viewExpanded}
				collapse={{
					id: 'generalAmounts',
					label: 'General Amounts'
				}}>
				{isContractor !== 'Contractor' && (
					<Grid item {...sizes[12]}>
						<Checkbox
							color="primary"
							name="manuallyEditFinancialValues"
							label="Edit financial values"
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* BASE VALUE */}
				{formFields.formFieldsConfig.baseValue && (
					<Grid item {...sizes[6]}>
						<Numeric
							required
							money
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							name="baseValue"
							label="Base Value"
							additionalOnBlur={() => {
								if (watch('company') !== 'HR') {
									initFinancialValues(watch());
									checkStartDateUpdateIRSTax();
									getAnnualGrossValue(watch());
								}
							}}
							additionalOnChange={() => {
								if (watch('company') == 'HR') {
									updateBonusSalary(watch());
									setValue('additionalExpenses', 0);
									setAddExpensesWhenNursery0(0);
								}
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* IRS TAX */}
				{formFields.formFieldsConfig.irsTax && (
					<Grid item {...sizes[6]}>
						<Numeric
							readOnly
							percentage
							name="irsToApply"
							label="IRS Tax"
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Work Schedule Exemption Value */}
				{formFields.formFieldsConfig.workScheduleExemptionValue && (
					<Grid item {...sizes[6]}>
						<Numeric
							readOnly
							money
							name="ihtValue"
							label="Work Schedule Exemption Value"
							additionalOnBlur={() => {
								checkStartDateUpdateIRSTax();
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Work Schedule Exemption Value % */}
				{formFields.formFieldsConfig.workScheduleExemptionPercentage && (
					<>
						<Grid item {...sizes[6]}>
							<Numeric
								percentage
								disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
								name="ihtPercentage"
								label="Work Schedule Exemption %"
								additionalOnBlur={() => {
									initFinancialValues(watch());
									checkStartDateUpdateIRSTax();
								}}
								control={control}
								errors={errors}
							/>
						</Grid>
						<FormDivider />
					</>
				)}
				{/* Twelfths CHECKBOX */}
				{formFields.formFieldsConfig.twelfths && (
					<Grid item {...sizes[12]}>
						<Checkbox
							color="primary"
							name="hasTwelfths"
							label="TWELFTHS"
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							additionalOnChange={() => {
								initFinancialValues(watch());
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Vacations Twelfth */}
				{formFields.formFieldsConfig.vacationsTwelfth && (
					<Grid
						item
						{...sizes[6]}
						className={clsx(classes.animation, {
							[classes.show]: watch('hasTwelfths')
						})}>
						<Numeric
							readOnly
							money
							name="vacationsTwelfth"
							label="Vacations Twelfth"
							additionalOnBlur={() => ({})}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Christmas Twelfth */}
				{formFields.formFieldsConfig.christmasTwelfth && (
					<>
						<Grid
							item
							{...sizes[6]}
							className={clsx(classes.animation, {
								[classes.show]: watch('hasTwelfths')
							})}>
							<Numeric
								readOnly
								money
								name="christmasTwelfth"
								label="Christmas Twelfth"
								additionalOnBlur={() => ({})}
								control={control}
								errors={errors}
							/>
						</Grid>
						<FormDivider />
					</>
				)}
				{/* Gross Base Value */}
				{formFields.formFieldsConfig.grossBaseValue && (
					<Grid item {...sizes[6]}>
						<Numeric
							readOnly
							money
							name="grossBaseValue"
							label="Gross Base Value"
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/*  Total Taxable Value */}
				{formFields.formFieldsConfig.totalTaxableValue && (
					<>
						<Grid item {...sizes[6]}>
							<Numeric
								readOnly
								money
								name="totalTaxableValue"
								label="Total Taxable Value"
								control={control}
								errors={errors}
							/>
						</Grid>
						<FormDivider />
					</>
				)}

				{/*  Food Allowance Type */}
				{formFields.formFieldsConfig.foodAllowanceType && (
					<Grid item {...sizes[6]}>
						<Select
							required
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							name="foodAllowanceType"
							label="Food Allowance Type"
							options={selectOptions.foodAllowanceTypes || []}
							additionalOnChange={() => {
								updateFoodAllowanceValue(watch());
								checkStartDateUpdateIRSTax();
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}

				{/* Food Allowance */}
				{formFields.formFieldsConfig.foodAllowance && (
					<Grid item {...sizes[6]}>
						<Numeric
							required
							money
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							name="foodAllowance"
							label="Food Allowance"
							additionalOnBlur={() => {
								checkStartDateUpdateIRSTax();
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* Food Allowance days */}
				{formFields.formFieldsConfig.foodAllowanceDays && (
					<>
						<Grid item {...sizes[6]}>
							<Numeric
								required
								disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
								name="foodAllowanceDays"
								label="Food Allowance days"
								minimumValue="0"
								maximumValue="30"
								additionalOnBlur={() => {
									checkStartDateUpdateIRSTax();
									getAnnualGrossValue(watch());
								}}
								control={control}
								errors={errors}
							/>
						</Grid>
					</>
				)}

				{/* National Allowance - HIDDEN */}
				{formFields.formFieldsConfig.nationalAllowance && (
					<Grid item {...sizes[6]} style={{ display: 'none' }}>
						<Numeric
							money
							name="nationalAllowance"
							label="National Allowance"
							additionalOnBlur={() => {
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* National Allowance Days - HIDDEN*/}
				{formFields.formFieldsConfig.nationalAllowanceDays && (
					<Grid item {...sizes[6]} style={{ display: 'none' }}>
						<Numeric
							name="nationalAllowanceDays"
							label="National Allowance days"
							minimumValue="0"
							maximumValue="30"
							additionalOnBlur={() => {
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* International Allowance - HIDDEN*/}
				{formFields.formFieldsConfig.internationalAllowance && (
					<Grid item {...sizes[6]} style={{ display: 'none' }}>
						<Numeric
							money
							name="internationalAllowance"
							label="International Allowance"
							additionalOnBlur={() => {
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* International Allowance Days - HIDDEN*/}
				{formFields.formFieldsConfig.internationalAllowanceDays && (
					<>
						<Grid item {...sizes[6]} style={{ display: 'none' }}>
							<Numeric
								name="internationalAllowanceDays"
								label="International Allowance days"
								additionalOnBlur={() => {
									getAnnualGrossValue(watch());
								}}
								minimumValue="0"
								maximumValue="30"
								control={control}
								errors={errors}
							/>
						</Grid>
					</>
				)}
				{/* Travel Pass - HIDDEN*/}
				{formFields.formFieldsConfig.travelPass && (
					<Grid item {...sizes[6]} style={{ display: 'none' }}>
						<Numeric
							money
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							name="travelPass"
							label="Travel Pass"
							additionalOnBlur={() => {
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* OTHER EXPENSES */}
				{formFields.formFieldsConfig.otherExpenses && (
					<Grid item {...sizes[6]}>
						<Numeric
							money
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							name="additionalExpenses"
							label="Other Expenses"
							additionalOnBlur={() => {
								getAnnualGrossValue(watch());
								setAddExpensesWhenNursery0(getValues('additionalExpenses'));
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* NURSERY CHECK */}
				{formFields.formFieldsConfig.nurseryCheck && (
					<Grid item {...sizes[6]}>
						<Numeric
							money
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							name="nurseryCheck"
							label="Nursery Check"
							additionalOnBlur={() => {
								getAnnualGrossValue(watch());
								//Updates values of other expenses depending of nursery check value
								setValue(
									'additionalExpenses',
									Number(addExpensesWhenNursery0) - getValues('nurseryCheck') > 0
										? Number(addExpensesWhenNursery0) - getValues('nurseryCheck')
										: 0
								);
								//Updates additional expenses back to original value if nursery check is 0
								if (getValues('nurseryCheck') == 0) {
									setValue('additionalExpenses', addExpensesWhenNursery0);
								}
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* LIFE INSURANCE */}
				{formFields.formFieldsConfig.lifeInsurance && (
					<Grid item {...sizes[6]}>
						<Numeric
							money
							disabled={getValues('manuallyEditFinancialValues') == true ? false : true}
							name="lifeInsurance"
							label="Life Insurance"
							additionalOnBlur={() => {
								initFinancialValues(watch());
								checkStartDateUpdateIRSTax();
								getAnnualGrossValue(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* HOURLY RATE */}
				{formFields.formFieldsConfig.hourlyRate && (
					<Grid item {...sizes[6]}>
						<Numeric
							money
							required
							name="hourlyRate"
							label="Hourly Rate"
							additionalOnChange={() => {
								updateBudget(watch());
							}}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
				{/* BUDGET */}
				{formFields.formFieldsConfig.monthlyBudget && (
					<Grid item {...sizes[6]}>
						<InfoField
							money
							required
							disabled
							name="budget"
							label="Monthly Budget"
							value={(Math.round(budgetValue * 100) / 100).toFixed(2) + '€'}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
			</GroupCollapse>

			{/* PRIZE BONUS */}
			{formFields.formFieldsConfig.employeeType != 'Contractor' && formFields.formFieldsConfig.country != 'HR' && (
				<GroupCollapse
					viewExpanded={viewExpanded}
					collapse={{
						id: 'prizeBonusGoals',
						label: 'Prize Bonus'
					}}>
					{/* CREATE ERP CHECKBOX ----- HIDDEN */}
					<Grid item {...sizes[12]} style={{ display: 'none' }}>
						<Checkbox name="createPrizeBonusErp" label="CREATE IN ERP?" control={control} errors={errors} />
					</Grid>
					{/* Maximum Prize Bonus */}
					{formFields.formFieldsConfig.maximumPrizeBonus && (
						<Grid item {...sizes[6]}>
							<Numeric
								money
								name="prizeBonus"
								label="Maximum Prize Bonus"
								additionalOnBlur={(val: string | number) => {
									if (val === '0.00') {
										setValue('eligiblePrizeValue', 0);
										setValue('prizeBonusPercentage', 0);
										setValue('prizeBonusGoals', []);
									} else {
										setValue(
											'eligiblePrizeValue',
											(Number(val) * Number(watch('monthsApplicable'))) / 12 || 12
										);
										setValue(
											'prizeBonusPercentage',
											(Number(val) / (Number(val) + Number(watch('annualGrossValue')))) * 100 || 0
										);
									}
									checkStartDateUpdateIRSTax();
								}}
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{/* % from Annual Gross Value */}
					{formFields.formFieldsConfig.percentageFromAnnualGrossValue && (
						<Grid item {...sizes[6]}>
							<Numeric
								readOnly
								percentage
								name="prizeBonusPercentage"
								label="% from Annual Gross Value"
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{/* Months Applicable */}
					{formFields.formFieldsConfig.monthsAppicable && (
						<Grid item {...sizes[6]}>
							<Numeric
								required
								name="monthsApplicable"
								label="Months Applicable"
								additionalOnBlur={val => {
									checkStartDateUpdateIRSTax();
									setValue('eligiblePrizeValue', (Number(watch('prizeBonus')) * Number(val)) / 12);
								}}
								control={control}
								errors={errors}
								minimumValue="1"
								maximumValue="12"
							/>
						</Grid>
					)}

					{/* Eligible Prize Value */}
					{formFields.formFieldsConfig.eligiblePrizeValue && (
						<Grid item {...sizes[6]}>
							<Numeric
								readOnly
								money
								name="eligiblePrizeValue"
								label="Eligible Prize Value"
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{/* IRS TAX */}
					{formFields.formFieldsConfig.irsTax && (
						<Grid item {...sizes[6]}>
							<Numeric
								readOnly
								percentage
								name="annualPrizeIRS"
								label="IRS Tax (in prize month)"
								helperText="IRS calculation is considering the Maximum Prize Bonus."
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{/* PRIZE GOALS */}
					{formFields.formFieldsConfig.eligiblePrizeValue && (
						<Grid
							item
							{...sizes[12]}
							className={clsx(classes.animation, {
								[classes.show]: showPrizeBonus
							})}>
							<PrizeGoals
								prizeBonus={prizeBonusValueToComponent}
								control={control}
								errors={errors}
								watch={watch}
								clearErrors={clearErrors}
								setValue={setValue}
								selectOptions={selectOptions}
							/>
						</Grid>
					)}
				</GroupCollapse>
			)}

			{/* BONUS SALARY */}
			{formFields.formFieldsConfig.country == 'HR' && formFields.formFieldsConfig.payBonusSalary && (
				<GroupCollapse
					viewExpanded={viewExpanded}
					collapse={{
						id: 'bonusSalary',
						label: 'Bonus Salary'
					}}>
					{/* Pay Bonus Salary Checkbox */}
					{formFields.formFieldsConfig.payBonusSalary && (
						<Grid item {...sizes[12]}>
							<Checkbox
								color="primary"
								name="payBonusSalary"
								label="PAY BONUS SALARY"
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
					{/* Bonus Salary Value */}
					{formFields.formFieldsConfig.bonusSalary && (
						<Grid
							item
							{...sizes[6]}
							className={clsx(classes.animation, {
								[classes.show]: watch('payBonusSalary')
							})}>
							<InfoField
								money
								required
								disabled
								name="bonusSalary"
								label="Bonus Salary Value"
								value={(Math.round(bonusSalaryValue * 100) / 100).toFixed(2) + '€'}
								control={control}
								errors={errors}
							/>
						</Grid>
					)}
				</GroupCollapse>
			)}

			{/* TAXES */}
			{formFields.formFieldsConfig.formType == 'Cost Salary Category' && taxes}
			{/* NOTES */}
			<GroupCollapse
				viewExpanded={viewExpanded}
				collapse={{
					id: 'notes',
					label: 'NOTES'
				}}>
				{/* NOTES */}
				{formFields.formFieldsConfig.comments && (
					<Grid item {...sizes[12]}>
						<Input
							label="Comments"
							name="careerAndFinancialNotes"
							multiline
							minRows={4}
							maxRows={8}
							control={control}
							errors={errors}
						/>
					</Grid>
				)}
			</GroupCollapse>
		</Grid>
	);
};

export default withLayout(CareerFinancial);
